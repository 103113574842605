/* eslint-disable no-console */
import mixpanel, { Dict, Query } from 'mixpanel-browser';

mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_KEY || '');
const enabled = process.env.NEXT_PUBLIC_IS_MIXPANEL_ENABLED;

const Mixpanel = {
  identify: (id?: string) => {
    if (enabled) mixpanel.identify(id);
  },
  alias: (id: string) => {
    if (enabled) mixpanel.alias(id);
  },
  track: (name: string, props: Dict | undefined) => {
    if (enabled) mixpanel.track(name, props);
    else console.log(name, props);
  },
  register: (props: Dict) => {
    if (enabled) mixpanel.register(props);
    else console.log('registered', props);
  },
  track_links: (
    query: Query,
    event_name: string,
    properties?: Dict | (() => void),
  ) => {
    if (enabled) mixpanel.track_links(query, event_name, properties);
  },
  track_forms: (
    query: Query,
    event_name: string,
    properties?: Dict | (() => void),
  ) => {
    if (enabled) mixpanel.track_forms(query, event_name, properties);
  },
  people: {
    set: (props: Dict) => {
      if (enabled) mixpanel.people.set(props);
      else console.log('Set user profile', props);
    },
    set_once: (props: Dict) => {
      if (enabled) mixpanel.people.set_once(props);
    },
    increment: (event: string, count: number) => {
      if (enabled) mixpanel.people.set_once(event, count);
      else console.log(`Incremented ${event} count by ${count}`);
    },
  },
};

export default Mixpanel;
