/* eslint no-param-reassign: 0 */
import { createSlice } from '@reduxjs/toolkit';
import { getUserIdFromCookie } from '../../util/browser';

interface ProfileState {
  userId: string;
  error: string | null;
}

const initialState: ProfileState = {
  userId: getUserIdFromCookie('basketful_u') || 'anonymous',
  error: null,
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile: (state, action) => {
      state.userId = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const profileActions = profileSlice.actions;

export default profileSlice.reducer;
