/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { Provider } from 'react-redux';
import {
  ThemeProvider,
  StyledEngineProvider,
  Theme,
} from '@mui/material/styles';
import Script from 'next/script';

import Mixpanel from '../util/mixpanel';
import store from '../redux/store';
import theme from '../util/theme';
import '../styles/globals.css';
import ErrorBoundary from 'src/components/Error/ErrorBoundary';

declare module '@mui/styles' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

function GoMyxx({ Component, pageProps }: AppProps | any) {
  const router = useRouter();
  const isPixelEnabled = process.env.NEXT_PUBLIC_FB_IS_PIXEL_ENABLED === 'true';

  useEffect(() => {
    function getQueryParam(url: string, param: string) {
      // Expects a raw URL
      // eslint-disable-next-line
      param = param.replace(/[[]/, "[").replace(/[]]/, "]");
      const regexS = `[?&]${param}=([^&#]*)`;
      const regex = new RegExp(regexS);
      const results: any = regex.exec(url);
      if (
        results === null ||
        (results && typeof results[1] !== 'string' && results[1].length)
      ) {
        return '';
      }
      return decodeURIComponent(results[1]).replace(/\W/gi, ' ');
    }

    const campaignParams = () => {
      const campaignKeywords =
        'utm_source utm_medium utm_campaign utm_content utm_term'.split(' ');
      let kw = '';
      const params: { [key: string]: any } = {};
      const firstParams: { [key: string]: any } = {};
      let index;
      for (index = 0; index < campaignKeywords.length; ++index) {
        kw = getQueryParam(document.URL, campaignKeywords[index]);
        if (kw.length) {
          params[`${campaignKeywords[index]} [last touch]`] = kw;
        }
      }
      // eslint-disable-next-line no-plusplus
      for (index = 0; index < campaignKeywords.length; ++index) {
        kw = getQueryParam(document.URL, campaignKeywords[index]);
        if (kw.length) {
          firstParams[`${campaignKeywords[index]} [first touch]`] = kw;
        }
      }
      Mixpanel.identify();
      Mixpanel.register(params);
      Mixpanel.people.set(params);
      Mixpanel.people.set_once(firstParams);
    };
    campaignParams();
  }, []);

  useEffect(() => {
    if (isPixelEnabled) {
      // this is weird, but see https://stackoverflow.com/questions/66696356/referenceerror-window-is-not-defined-on-nextjs
      import('react-facebook-pixel')
        .then(x => x.default)
        .then(ReactPixel => {
          ReactPixel.init(process.env.NEXT_PUBLIC_FB_PIXEL_KEY || ''); // facebookPixelId
          ReactPixel.pageView();

          router.events.on('routeChangeComplete', () => {
            ReactPixel.pageView();
          });
        });
    }
  }, [router.events]);

  return (
    <>
      {/* nextjs requires that client scripts not be loaded in pages (pages are server side) */}
      <Script strategy="afterInteractive" id="google-gtm-tracking">
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_TRACKING_ID}');
            `}
      </Script>
      <Script
        strategy="afterInteractive"
        id="google-analytics-tracking-script"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_TRACKING_ID}`}
      />
      <Script strategy="afterInteractive" id="google-gtm-tracking">
        {`window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GA_TRACKING_ID || ''}', {
              page_path: window.location.pathname,
            });
            `}
      </Script>
      <Provider store={store}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <ErrorBoundary>
              <Component {...pageProps} />
            </ErrorBoundary>
          </ThemeProvider>
        </StyledEngineProvider>
      </Provider>
    </>
  );
}

export default GoMyxx;
