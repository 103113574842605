export const getAppVersion = (): { version?: string; sha?: string } => {
  const sha = process.env.NEXT_PUBLIC_COMMIT_SHA;
  const version = process.env.NEXT_PUBLIC_COMMIT_TAG;
  return {
    version,
    sha,
  };
};

export const getCookie = (key: string): string | null => {
  if (!key) {
    return null;
  }
  if (typeof document !== 'undefined') {
    return (
      decodeURIComponent(
        document.cookie.replace(
          new RegExp(
            '(?:(?:^|.*;)\\s*' +
              encodeURIComponent(key).replace(/[-.+*]/g, '\\$&') +
              '\\s*\\=\\s*([^;]*).*$)|^.*$',
          ),
          '$1',
        ),
      ) || null
    );
  } else {
    return null;
  }
};

export const getUserIdFromCookie = (key: string): string | null => {
  return getCookie(key)?.split('_')[0] ?? null;
};
