import { configureStore } from '@reduxjs/toolkit';
import profileSlice from './states/profileSlice';

import recipesSlice from './states/recipeSlice';

export function createStore() {
  return configureStore({
    reducer: {
      recipes: recipesSlice,
      profile: profileSlice,
    },
  });
}
const store = createStore();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
