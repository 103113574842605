import { Grid, Typography } from '@mui/material';
import lottie from 'lottie-web';
import React, { FC, useRef, useEffect, memo } from 'react';
import chefPlug from '../../lotties/chefPlug.json';

interface ChefErrorProps {
  size?: string;
  title?: string;
  subtitle?: string;
}
const defaultTitle = 'Sorry!';
const defaultSubtitle =
  'Something went wrong while processing your request. Please try again.';

const ChefError: FC<ChefErrorProps> = ({ size, title, subtitle }) => {
  const container = useRef(null as any);

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: chefPlug,
    });
  }, []);

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      data-testid="chef-loader"
    >
      <div
        style={{
          width: size,
          height: size,
        }}
        ref={container}
      />
      <Typography variant="h2" sx={{ mt: 2 }}>
        {title ? title : defaultTitle}
      </Typography>
      <Typography variant="body1" sx={{ fontSize: 20, mt: 2 }}>
        {subtitle ? subtitle : defaultSubtitle}
      </Typography>
    </Grid>
  );
};

export default memo(ChefError);
