import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    primary: {
      main: '#E6700A',
      light: '#FEEEE9',
      // TODO - we currently have no primary.dark in the style guide
      dark: '#d13606',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
      paper: '#fff',
    },
  },
  typography: {
    fontFamily: 'Lato',
    h1: {
      // fontSize: "1.125rem",
    },
    h2: {
      // fontSize: "1.125rem",
    },
    h3: {
      // fontSize: "1.125rem",
    },
    h4: {
      fontSize: '2.250rem',
    },
    h5: {},
    h6: {
      fontSize: '1.125rem',
    },
    subtitle1: {},
    subtitle2: {},
    body1: {
      fontWeight: 300,
    },
    body2: {},
    button: {},
    caption: {},
    overline: {},
  },
  zIndex: {
    modal: 500,
  },
  components: {
    MuiSnackbar: {
      styleOverrides: {
        root: {
          marginBottom: '55px',
        },
      },
    },
  },
});
export default theme;
