/* eslint no-param-reassign: 0 */
import { createSlice } from '@reduxjs/toolkit';
import {
  BundleLineItems,
  BundleRecipe,
  ContentfulBrandFields,
} from '../../util/bonsaiClient';
import { SiteListBasket } from '../../util/basketfuljsClient';

interface RecipeState {
  error: string | null;
  bundleRecipes: BundleRecipe[];
  bundleLineItems: BundleLineItems[];
  selectedRecipeId: string;
  siteBasket: SiteListBasket;
  basketfulJsIsReady: boolean;
  recipeIsBusy: boolean;
  brandDetails: ContentfulBrandFields;
  brandFetched: boolean;
}

const initialState: RecipeState = {
  error: null,
  bundleRecipes: [] as BundleRecipe[],
  bundleLineItems: [] as BundleLineItems[],
  selectedRecipeId: '',
  siteBasket: {
    id: '',
    listItems: [],
    listRecipes: [],
    listBundles: [],
    count: 0,
  } as SiteListBasket,
  basketfulJsIsReady: false,
  recipeIsBusy: false,
  brandDetails: {} as ContentfulBrandFields,
  brandFetched: false,
};

const recipeSlice = createSlice({
  name: 'recipes',
  initialState,
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    setBundleRecipes: (state, action) => {
      state.bundleRecipes = action.payload;
    },
    setBundleLineItems: (state, action) => {
      state.bundleLineItems = action.payload;
    },
    setSelectedRecipeId: (state, action) => {
      state.selectedRecipeId = action.payload;
    },
    setSiteBasket: (state, action) => {
      state.siteBasket = action.payload;
    },
    addListBundle: (state, action) => {
      state.siteBasket.listBundles.push(action.payload);
    },
    removeListBundle: (state, action) => {
      state.siteBasket.listBundles = state.siteBasket.listBundles.filter(
        b => b.id !== action.payload.id,
      );
    },
    setBasketfulJsIsReady: (state, action) => {
      state.basketfulJsIsReady = action.payload;
    },
    setRecipeIsBusy: (state, action) => {
      state.recipeIsBusy = action.payload;
    },
    setSiteBasketCount: (state, action) => {
      state.siteBasket.count = action.payload;
    },
    addListBundleItem: (state, action) => {
      state.siteBasket.listItems.push(action.payload);
    },
    removeListBundleItem: (state, action) => {
      state.siteBasket.listItems = state.siteBasket.listItems.filter(
        item => item.upc !== action.payload.upc,
      );
    },
    setBrandDetails: (state, action) => {
      state.brandDetails = action.payload;
      state.brandFetched = true;
    },
    resetSiteBasket: state => {
      state.siteBasket = {
        ...state.siteBasket,
        listItems: [],
        listRecipes: [],
        listBundles: [],
        count: 0,
      };
    },
  },
});

export const recipeActions = recipeSlice.actions;

export default recipeSlice.reducer;
