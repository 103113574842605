var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"Qttih1Q8-rarziYL1pwv8"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";
import { getAppVersion } from './src/util/browser'

const appVersion = getAppVersion().version;
const environment = appVersion === undefined ? 'lower' : 'production';
const release = environment === 'lower' ? getAppVersion().sha : appVersion;

// enable sentry and global error interceptors to send to sentry
if (process.env.NEXT_PUBLIC_SENTRY_ENABLED === 'true') {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_URI,
    release: `gomyxx@${release}`,
    integrations: [
      Sentry.FunctionToString(),
      Sentry.browserApiErrorsIntegration(),
      Sentry.breadcrumbsIntegration({ console: false }),
      Sentry.linkedErrorsIntegration(),
      new Sentry.BrowserTracing(),
    ],
    ignoreUrls: [/google-analytics\.com/i],
    defaultIntegrations: false,
    tracesSampleRate: 0.01,
    environment,
    beforeSend(event) {
      // disable when running locally
      // might just want to base this on app version
      if (process.env.NODE_ENV === 'development') {
        return null;
      }
      if (window.location.pathname.includes('/health')) {
        return null;
      }
      if (
        window?.navigator?.userAgent?.indexOf('GoogleSecurityScanner') !== -1
      ) {
        return null;
      }
      return event;
    },
    });
}
